<template>
  <div>
    <vs-table search stripe border description :sst="true" :data="tableData" :max-items="table.length"
      :total="totalPage" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              ">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button size="small" color="success" type="border" @click="handleExport('excel')">Excel</vs-button>
        </div>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th sort-key="doc_date">Doc Date</vs-th>
        <vs-th sort-key="po_number">PO Number</vs-th>
        <vs-th sort-key="gr_number">GR Number</vs-th>
        <vs-th sort-key="gr_type">GR Type</vs-th>
        <vs-th sort-key="ref_doc">Ref Doc</vs-th>
        <vs-th sort-key="po_type">PO Type</vs-th>
        <vs-th sort-key="sj_no_logistic">SJ No Logistic</vs-th>
        <vs-th sort-key="material_code">Material Code</vs-th>
        <vs-th sort-key="material_description">Material Description</vs-th>
        <vs-th sort-key="gr_qty">GR Qty</vs-th>
        <vs-th sort-key="gr_uom">GR Uom</vs-th>
        <vs-th sort-key="gr_amount">GR Amount</vs-th>
        <vs-th sort-key="movement_type">Movement Type</vs-th>
        <vs-th sort-key="sales_office_code">Sales Office Code</vs-th>
        <vs-th sort-key="StorageLocation">Storage Location</vs-th>
        <vs-th sort-key="created_at">Created At</vs-th>
        <vs-th sort-key="principal_code">Principal Code</vs-th>
        <vs-th sort-key="vendor_code">Vendor Code</vs-th>
        <vs-th sort-key="vendor_name">Vendor Name</vs-th>
        <vs-th sort-key="gr_product_status">GR Product Status</vs-th>
        <vs-th sort-key="gr_doc_status">GR Doc Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].doc_date">
            {{ dateFormat(data[indextr].doc_date) }}
          </vs-td>
          <vs-td :data="data[indextr].po_number">
            {{ data[indextr].po_number }}
          </vs-td>
          <vs-td :data="data[indextr].gr_number">
            {{ data[indextr].gr_number }}
          </vs-td>
          <vs-td :data="data[indextr].gr_type">
            {{ data[indextr].gr_type }}
          </vs-td>
          <vs-td :data="data[indextr].ref_doc">
            {{ data[indextr].ref_doc }}
          </vs-td>
          <vs-td :data="data[indextr].po_type">
            {{ data[indextr].po_type }}
          </vs-td>
          <vs-td :data="data[indextr].sj_no_logistic">
            {{ data[indextr].sj_no_logistic }}
          </vs-td>
          <vs-td :data="data[indextr].material_code">
            {{ data[indextr].material_code }}
          </vs-td>
          <vs-td :data="data[indextr].material_description">
            {{ data[indextr].material_description }}
          </vs-td>
          <vs-td :data="data[indextr].gr_qty">
            {{ data[indextr].gr_qty }}
          </vs-td>
          <vs-td :data="data[indextr].gr_uom">
            {{ data[indextr].gr_uom }}
          </vs-td>
          <vs-td :data="data[indextr].gr_amount">
            {{ data[indextr].gr_amount }}
          </vs-td>
          <vs-td :data="data[indextr].movement_type">
            {{ data[indextr].movement_type }}
          </vs-td>
          <vs-td :data="data[indextr].sales_office_code">
            {{ data[indextr].sales_office_code }}
          </vs-td>
          <vs-td :data="data[indextr].StorageLocation">
            {{ data[indextr].StorageLocation }}
          </vs-td>
          <vs-td :data="data[indextr].created_at">
            {{ data[indextr].created_at }}
          </vs-td>
          <vs-td :data="data[indextr].principal_code">
            {{ data[indextr].principal_code }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_code">
            {{ data[indextr].vendor_code }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_name">
            {{ data[indextr].vendor_name }}
          </vs-td>
          <vs-td :data="data[indextr].gr_product_status">
            {{ data[indextr].gr_product_status }}
          </vs-td>
          <vs-td :data="data[indextr].gr_doc_status">
            {{ data[indextr].gr_doc_status }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="totalPage" v-model="setPage" />
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex/dist/vuex.common.js";
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    postingDateStart: {
      type: Date,
    },
    postingDateEnd: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    vendorCodeIDs: {
      type: Array,
    },
    vendorPoTypeIDs: {
      type: Array,
    },
    vendorStatusIDs: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ getGRReport: 'reportGR/getGRReport', generateGRReport: 'reportGR/generateGRReport', }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleExport() {
        this.$vs.loading();
        this.generateGRReport(
        {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search == '' ? undefined : this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          start_doc_posting_date:
            this.postingDateStart == null
              ? undefined
              : moment(this.postingDateStart).format('YYYY-MM-DD'),
          end_doc_posting_date:
            this.postingDateEnd == null
              ? undefined
              : moment(this.postingDateEnd).format('YYYY-MM-DD'),
          vendor_code:
            this.vendorCodeIDs.length == 0 ? undefined : this.vendorCodeIDs.join(','),
          po_type:
            this.vendorPoTypeIDs.length == 0 ? undefined : this.vendorPoTypeIDs.join(','),
          status:
            this.vendorStatusIDs.length == 0 ? undefined : this.vendorStatusIDs.join(','),
        }
      ).then(() => {
        this.$vs.loading.close();
      })
        },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.getGRReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search == '' ? undefined : this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          start_doc_posting_date:
            this.postingDateStart == null
              ? undefined
              : moment(this.postingDateStart).format('YYYY-MM-DD'),
          end_doc_posting_date:
            this.postingDateEnd == null
              ? undefined
              : moment(this.postingDateEnd).format('YYYY-MM-DD'),
          territory:
            this.territoryIDs.length == 0 ? undefined : this.territoryIDs.join(','),
          vendor_code:
            this.vendorCodeIDs.length == 0 ? undefined : this.vendorCodeIDs.join(','),
          po_type:
            this.vendorPoTypeIDs.length == 0 ? undefined : this.vendorPoTypeIDs.join(','),
          status:
            this.vendorStatusIDs.length == 0 ? undefined : this.vendorStatusIDs.join(','),
          }).then(() => {
          this.$vs.loading.close();
        });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    draw() {
      this.getData();
    },
    dataList(val) {
            this.table.start = ((this.table.page - 1) * this.table.length) + 1
            this.table.end = ((this.table.page - 1) * this.table.length) + val.length
        },
        generateReport(val) {
            if (val && val.status === 'OK') {
                this.$vs.notify({
                    color: "success",
                    title: "Processing",
                    text: val.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: val.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
             }
        },
        totalRecord(val) {
            this.table.total = val
        },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportGR.tableData,
      totalPage: (state) => state.reportGR.totalPage,
      totalRecord: (state) => state.reportGR.total,
      generateReport: (state) => state.reportGR.generateReport,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>